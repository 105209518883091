/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
  styled,
  useTheme
} from "@mui/material";
import { Box } from "@mui/system";
import { isEmpty, isEqual } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CheckBoxChecked, CheckBoxNone } from "../../../../public/assets/icons/CheckBox";
import XCircle from "../../../../public/assets/icons/XCircle";
import { TTheme } from "../../../utils/configs/setupTheme";
import { genSignature, getNonce } from "../../../utils/helpers/helpers";
import IOSSwitch from "../../common/components/IOSSwitch";
import { commonActions } from "../../common/redux/actions";
import { TCommonState } from "../../common/utils/type";
import { accountActions } from "../redux/actions";
import { TAccountState } from "../utils/types";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: 560,
    padding: "8px",
    borderRadius: "6px"
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const learningPerDayOptions = [15, 30, 45, 60, 120, 180, 240, 300, 360, 420];
const correctQuizRateOptions = [0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.9, 1.0];
const targetScoreList = [5, 6, 7, 8, 9, 10];

export default function TargetDialog({ setOpen, open }) {
  const target = useSelector((state: { account: TAccountState }) => state.account.goals);
  const { grade } = useSelector((state: { commonNew: TCommonState }) => state?.commonNew);
  const [openSetScore, setOpenSetScore] = useState<boolean>(!isEmpty(target?.goal_subjects));
  const [subjectScore, setSubjectScore] = useState<{ listSubjectCheckBox: string[]; listSubjectScore: any }>({
    listSubjectCheckBox: [],
    listSubjectScore: []
  });
  const theme: TTheme = useTheme();
  const dispatch = useDispatch();
  const subjectTypes = target?.subject_types;

  const handleGetGoals = () => {
    dispatch(accountActions.handleGetGoals({ grade_slug: grade }));
  };
  const handleSetListSubjectSetScore = (value: string) => {
    if (subjectScore?.listSubjectCheckBox?.includes(value)) {
      setSubjectScore({
        ...subjectScore,
        listSubjectCheckBox: subjectScore?.listSubjectCheckBox?.filter((e) => e !== value)
      });
    } else {
      setSubjectScore({ ...subjectScore, listSubjectCheckBox: subjectScore?.listSubjectCheckBox?.concat([value]) });
    }
  };

  const handleSetSubjectScore = (subjectTypeId, score) => {
    const listSubjectScore = subjectScore?.listSubjectScore;
    if (
      listSubjectScore?.length > 0 &&
      listSubjectScore?.findIndex((ele) => ele?.subject_type_id === subjectTypeId) > -1
    ) {
      // update score của subject đã có trong list
      const newListSubjectScore = listSubjectScore.map((obj) => {
        if (obj?.subject_type_id === subjectTypeId) {
          return { ...obj, point_target: score };
        }
        return obj;
      });
      setSubjectScore({ ...subjectScore, listSubjectScore: newListSubjectScore });
    } else {
      setSubjectScore({
        ...subjectScore,
        listSubjectScore: listSubjectScore.concat([{ subject_type_id: subjectTypeId, point_target: score }])
      });
    }
  };
  const handleChangeLearningPerDay = (event) => {
    dispatch(accountActions.handleChangeTarget("learning_per_day", event.target.value));
  };
  const handleChangeCorrectQuizRate = (event) => {
    dispatch(accountActions.handleChangeTarget("correct_quiz_rate", event.target.value));
  };

  const handleSubmit = () => {
    const dataPostPre: any = {
      learning_per_day: target?.learning_per_day,
      correct_quiz_rate:
        target?.correct_quiz_rate === 1 ? target?.correct_quiz_rate.toFixed(1) : target?.correct_quiz_rate,
      timestamp: moment().unix(),
      nonce: getNonce(),
      grade_slug: grade
    };
    const dataPost: any = {
      ...dataPostPre,
      signature: genSignature(dataPostPre)
    };
    if (subjectScore?.listSubjectScore?.length > 0) {
      const listSubjectTypeIdOfPrevGoalSubject = target.goal_subjects?.map(
        (goalSubject) => goalSubject.subject_type_id
      );
      const filteredSubjectScoreUnpost = openSetScore
        ? subjectScore?.listSubjectScore.filter(
            (subjectScoreItem) =>
              listSubjectTypeIdOfPrevGoalSubject?.includes(subjectScoreItem.subject_type_id) ||
              subjectScore.listSubjectCheckBox.includes(subjectScoreItem.subject_type_id.toString())
          )
        : subjectScore?.listSubjectScore.filter((subjectScoreItem) =>
            listSubjectTypeIdOfPrevGoalSubject?.includes(subjectScoreItem.subject_type_id)
          );

      dataPost.goal_subjects = filteredSubjectScoreUnpost.map((e) => {
        return {
          subject_type_id: e.subject_type_id,
          point_target: e.point_target,
          is_delete: !openSetScore ? true : !subjectScore?.listSubjectCheckBox?.includes(String(e.subject_type_id))
        };
      });
    }
    dispatch(accountActions.handlePostGoals(dataPost, () => dispatch(commonActions.clearStaticData())));
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
    // if (!target?.is_updated) {
    //   handleSubmit();
    // }
  };
  useEffect(() => {
    if (open) {
      handleGetGoals();
    }
  }, [open]);

  useEffect(() => {
    if (isEmpty(target.goal_subjects)) {
      setSubjectScore({
        listSubjectCheckBox: [],
        listSubjectScore: target?.subject_types?.map((subjectType) => ({
          subject_type_id: subjectType.id,
          point_target: targetScoreList[targetScoreList.length - 1]
        }))
      });
      setOpenSetScore(false);
    } else if (!openSetScore || !isEqual(target.goal_subjects, subjectScore?.listSubjectScore)) {
      if (!openSetScore) {
        setOpenSetScore(true);
      }
      setSubjectScore({
        listSubjectCheckBox: target?.goal_subjects?.map((e) => String(e?.subject_type_id)) || [],
        listSubjectScore: [
          ...(target?.subject_types
            ? target.subject_types
                .filter(
                  (elm) => !target?.goal_subjects?.map((goalSubject) => goalSubject.subject_type_id).includes(elm.id)
                )
                .map((subjectType) => {
                  return { subject_type_id: subjectType.id, point_target: targetScoreList[targetScoreList.length - 1] };
                })
            : []),
          ...(target?.goal_subjects ? target.goal_subjects : [])
        ]
      });
    }
  }, [target.goal_subjects, target?.subject_types]);

  return (
    <CustomDialog
      open={open}
      onClose={(_, reason) => reason !== "backdropClick" && handleClose()}
      sx={{ overflow: "scroll" }}
    >
      <DialogTitle className="center-row text-black pt-4">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 2,
            top: 2
          }}
        >
          <XCircle />
        </IconButton>
        <span className="text-title">
          <FormattedMessage id="Profile.target" />
        </span>
      </DialogTitle>
      <DialogContent className="flex flex-col">
        <Box className="sm:w-full flex items-center justify-between gap-2 sm:py-4 md:p-4">
          <Box display="flex" alignItems="center" width="242px">
            <Typography variant="body1" className="md:ml-2 sm:ml-0" style={{ color: "black", fontSize: "15px" }}>
              <FormattedMessage id="IDS_Times_Learn_EveryDay" />
            </Typography>
          </Box>
          <FormControl size="small">
            <Select
              value={target?.learning_per_day}
              onChange={handleChangeLearningPerDay}
              className="font-[500] text-[13px]"
              sx={{
                boxShadow: "0px 2px 6px 0px rgba(16, 24, 40, 0.2)",
                height: 32,
                width: 115,
                borderRadius: 2,
                ".MuiOutlinedInput-notchedOutline": { border: 0 }
              }}
              MenuProps={{
                sx: {
                  "&& .Mui-selected": {
                    backgroundColor: theme.palette.primary.light
                  },
                  "&& .Mui-selected[aria-selected=true]": {
                    backgroundColor: theme.palette.primary.light
                  }
                }
              }}
            >
              {learningPerDayOptions.map((option, idx) => {
                if (option >= 60) {
                  return (
                    <MenuItem
                      value={option}
                      key={idx}
                      className={`text-[13px] ${
                        option === target?.learning_per_day ? "" : "hover:bg-green/10 duration-200"
                      }`}
                    >
                      {option / 60} <FormattedMessage id="Objective.hour" />
                    </MenuItem>
                  );
                }
                return (
                  <MenuItem
                    value={option}
                    key={idx}
                    className={`text-[13px] ${
                      option === target?.learning_per_day ? "" : "hover:bg-green/10 duration-200"
                    }`}
                  >
                    {option} <FormattedMessage id="Objective.minute" />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box className="sm:w-full flex items-center justify-between gap-2 sm:py-4 md:p-4">
          <Box display="flex" alignItems="center" width="242px">
            <Typography variant="body1" className="md:ml-2 sm:ml-0" style={{ color: "black", fontSize: "15px" }}>
              <FormattedMessage id="Objective.correctRate" />
            </Typography>
          </Box>
          <FormControl size="small">
            <Select
              value={target?.correct_quiz_rate}
              onChange={handleChangeCorrectQuizRate}
              className="font-[500] text-[13px]"
              sx={{
                boxShadow: "0px 2px 6px 0px rgba(16, 24, 40, 0.2)",
                height: 32,
                width: 115,
                borderRadius: 2,
                ".MuiOutlinedInput-notchedOutline": { border: 0 }
              }}
              MenuProps={{
                sx: {
                  "&& .Mui-selected": {
                    backgroundColor: theme.palette.primary.light
                  },
                  "&& .Mui-selected[aria-selected=true]": {
                    backgroundColor: theme.palette.primary.light
                  }
                }
              }}
            >
              {correctQuizRateOptions.map((option, idx) => (
                <MenuItem
                  value={option}
                  key={idx}
                  className={`text-[13px] ${
                    option === target?.correct_quiz_rate ? "" : "hover:bg-green/10 duration-200"
                  }`}
                >
                  {(option * 100).toFixed()}%
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box className="w-full flex items-center justify-between gap-2 sm:py-4 md:p-4">
          <Box display="flex" alignItems="center" width="242px">
            <Typography variant="body1" className="md:ml-2 sm:ml-0" style={{ color: "black", fontSize: "15px" }}>
              <FormattedMessage id="target.setTargetScore" />
            </Typography>
          </Box>
          <IOSSwitch checked={openSetScore} value={openSetScore} onChange={() => setOpenSetScore(!openSetScore)} />
        </Box>
        {openSetScore && (
          <Box className="sm:w-full px-4 ">
            {subjectTypes?.map((subject) => (
              <div key={subject?.id} className="flex items-center justify-between">
                <div>
                  <Checkbox
                    sx={{
                      "&.Mui-checked": {
                        color: theme.palette.primary.main
                      }
                    }}
                    icon={<CheckBoxNone />}
                    checkedIcon={<CheckBoxChecked />}
                    value={subject.id}
                    checked={subjectScore?.listSubjectCheckBox?.includes(String(subject?.id))}
                    onChange={(e) => handleSetListSubjectSetScore(e?.target?.value)}
                  />
                  <span className="text-[14px] text-black1">{subject.title}</span>
                </div>
                <Select
                  value={subjectScore?.listSubjectScore?.find((e) => e?.subject_type_id === subject?.id)?.point_target}
                  onChange={(e) => handleSetSubjectScore(subject.id, e?.target?.value)}
                  className="font-[500] text-[13px]"
                  disabled={!subjectScore?.listSubjectCheckBox?.includes(String(subject.id))}
                  displayEmpty
                  renderValue={(selected) => (
                    <span>
                      {selected} <FormattedMessage id="target.score" />
                    </span>
                  )}
                  sx={{
                    boxShadow: "0px 2px 6px 0px rgba(16, 24, 40, 0.2)",
                    height: 32,
                    width: 115,
                    borderRadius: 2,
                    ".MuiOutlinedInput-notchedOutline": { border: 0 }
                  }}
                  MenuProps={{
                    sx: {
                      "&& .Mui-selected": {
                        backgroundColor: theme.palette.primary.light
                      },
                      "&& .Mui-selected[aria-selected=true]": {
                        backgroundColor: theme.palette.primary.light
                      }
                    }
                  }}
                >
                  {targetScoreList.map((option) => (
                    <MenuItem
                      value={option}
                      key={option}
                      className={`text-[13px] ${
                        option ===
                        subjectScore?.listSubjectScore?.find((e) => e?.subject_type_id === subject?.id)?.point_target
                          ? ""
                          : "hover:bg-green/10 duration-200"
                      }`}
                    >
                      <span>
                        {option} <FormattedMessage id="target.score" />
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            ))}
          </Box>
        )}
        <div className="center-row w-full gap-10 mb-4">
          <Button
            type="button"
            onClick={handleSubmit}
            className="text-white bg-primary font-semibold rounded-[100px] text-[13px] w-[149px] h-[44px]"
            sx={{ textTransform: "none" }}
          >
            <FormattedMessage id="common.save" />
          </Button>
        </div>
      </DialogContent>
    </CustomDialog>
  );
}
